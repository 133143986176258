import React, { useMemo, useRef } from "react";

import { useGate } from "@nestoca/gate-react";
import { Table, Typography, useBreakpointValue } from "@nestoca/ui";
import { useGetApplications } from "@shared/api/hooks/applications";
import {
  APPLICATION_STATE_GROUP,
  ApplicationState,
  TransactionTypeEnum,
} from "@shared/constants";
import {
  useGetFiltersInitialState,
  useGetTransactionTypeFilterOptions,
} from "@shared/ui";
import { formatDate, formatPercentage } from "@shared/utils";
import { keepPreviousData } from "@tanstack/react-query";
import { ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";
import { useTranslation } from "next-i18next";
import { toast } from "react-toastify";

import { DigitalChip } from "@components/digital-chip";
import { PostFetchLink } from "@components/post-fetch-link";
import {
  useGetTableParsedQueryParams,
  useTablesContext,
  useTableStateHandlers,
} from "@hooks";
import { mergeColumnFilters } from "@utils";

import {
  DigitalHeaderCell,
  LoanValueCell,
  NameCell,
  ProductTypeCell,
  StatusCell,
  TransactionTypeCell,
} from "./cell-content";
import styles from "./dashboard-table.module.scss";
import { EmptyState } from "./empty-state";
import { ApplicationTableItem, ApplicationTableItemProductType } from "./types";
import {
  applicationTransformer,
  getTenantReferralIdDisplay,
  getCurrentStatusFilterOptions,
  getDigitalFilterOptions,
  getProductTypeFilterOptions,
  mapColumnFilters,
  useColumnsByRole,
} from "./utils";

export const ActiveTable = () => {
  const {
    t,
    i18n: { language: locale },
  } = useTranslation("dashboard");
  const isMobile = useBreakpointValue({ default: true, md: false });

  const gate = useGate();
  const role = gate.getRole();
  const isExternalBroker = role === "externalbroker";

  const isExternalBrokerMobile = isExternalBroker && isMobile;

  // Used to update the url query params
  const urlSearchParamsRef = useRef(new URLSearchParams());

  const urlSearchParams = urlSearchParamsRef.current;

  const { onSetUrlSearchParamsByTableTab } = useTablesContext();

  const { onColumnFiltersChange, onPaginationChange, onSortingChange } =
    useTableStateHandlers(urlSearchParams, () =>
      onSetUrlSearchParamsByTableTab(
        APPLICATION_STATE_GROUP.ACTIVE_GROUP,
        urlSearchParams
      )
    );

  const {
    columnFilters: columnFiltersParams,
    pagination: paginationParams,
    sorting: sortingParams,
  } = useGetTableParsedQueryParams();

  const filtersInitialState = useGetFiltersInitialState(
    APPLICATION_STATE_GROUP.ACTIVE_GROUP,
    role
  );

  const mergedColumnFilters = mergeColumnFilters(
    filtersInitialState,
    columnFiltersParams
  );

  const payload = {
    sorting: sortingParams,
    filters: mapColumnFilters(mergedColumnFilters, role),
    pageIndex: paginationParams.pageIndex,
    pageSize: paginationParams.pageSize,
  };

  const { data, isError, isPending, isFetched } = useGetApplications(
    { ...payload, isExternalBroker },
    {
      select: (data) => {
        const results = data.results?.map((application) =>
          applicationTransformer(application)
        );

        return { ...data, results };
      },
      placeholderData: keepPreviousData,
    }
  );

  const results = data?.results || [];

  const pageCount = data?.pageCount || 0;

  const urlLink = isExternalBroker && !isMobile ? "/documents" : "";

  const transactionTypeFilterOptions = useGetTransactionTypeFilterOptions(role);

  const baseColumns: ColumnDef<ApplicationTableItem>[] = useMemo(
    () => [
      {
        id: "id",
        accessorKey: "id",
        header: t("table.header.id"),
        cell: ({ getValue }) => (
          <PostFetchLink
            href={`/${getValue<number>()}${urlLink}`}
          >{`#${getValue<number>()}`}</PostFetchLink>
        ),
        enableColumnFilter: false,
        meta: {
          className: styles["column-nowrap"],
        },
      },
      {
        id: "tenantReferralId",
        accessorKey: "tenantReferralId",
        header: t("table.header.tenantReferralId"),
        cell: ({ getValue }) =>
          getTenantReferralIdDisplay(getValue<number | null>()),
        enableSorting: false,
        enableColumnFilter: false,
        meta: {
          className: styles["column-nowrap"],
        },
      },
      {
        id: "name",
        accessorKey: "name",
        header: t("table.header.name"),
        cell: ({ getValue, row }) => (
          <NameCell
            name={getValue<string>()}
            applicationTableItem={row.original}
          />
        ),
        enableColumnFilter: false,
        meta: {
          className: clsx(styles["column-nowrap"], {
            [styles["column-nowrap--mobile"]]: isMobile,
          }),
        },
      },
      {
        id: "isDigital",
        accessorKey: "isDigital",
        header: () => <DigitalHeaderCell />,
        cell: ({ getValue }) => <DigitalChip isDigital={getValue<boolean>()} />,
        enableSorting: false,
        filterFn: "defaultFilterFn",
        meta: {
          className: styles["column-nowrap"],
          filterOptions: getDigitalFilterOptions(t),
        },
      },
      {
        id: "transactionType",
        accessorKey: "transactionType",
        header: t("table.header.transactionType"),
        cell: ({ getValue }) => (
          <TransactionTypeCell
            applicationType={getValue<TransactionTypeEnum>()}
          />
        ),
        filterFn: "defaultFilterFn",
        meta: {
          className: styles["column-nowrap"],
          filterOptions: transactionTypeFilterOptions,
        },
      },
      {
        id: "rate",
        accessorKey: "rate",
        header: t("details.rate", { ns: "applications" }),
        cell: ({ getValue }) =>
          formatPercentage(getValue<number | undefined>(), {
            maximumFractionDigits: 3,
          }),
        enableColumnFilter: false,
      },
      {
        id: "productType",
        accessorKey: "productType",
        header: t("table.header.productType"),
        cell: ({ getValue, row }) => (
          <ProductTypeCell
            productType={getValue<ApplicationTableItemProductType>()}
            applicationTableItem={row.original}
          />
        ),
        filterFn: "defaultFilterFn",
        meta: {
          className: styles["column-nowrap"],
          filterOptions: getProductTypeFilterOptions(t),
        },
      },
      {
        id: "loanValue",
        accessorKey: "loanValue",
        header: t("table.header.loanValue"),
        cell: ({ getValue, row }) => (
          <LoanValueCell
            loanValue={getValue<number | undefined>()}
            applicationTableItem={row.original}
          />
        ),
        enableColumnFilter: false,
        meta: {
          className: styles["column-nowrap"],
        },
      },
      {
        id: "startingDate",
        accessorKey: "startingDate",
        header: t("table.header.startingDate"),
        cell: ({ getValue }) => formatDate(getValue<string>(), locale),
        enableColumnFilter: false,
        meta: {
          className: styles["column-nowrap"],
        },
      },
      {
        id: "closingDate",
        accessorKey: "closingDate",
        header: t("table.header.closingDate"),
        cell: ({ getValue }) => getValue<string>() || "-",
        enableColumnFilter: false,
        meta: {
          className: styles["column-nowrap"],
        },
      },
      {
        id: "currentStatus",
        accessorKey: "currentStatus",
        header: t("table.header.currentStatus"),
        cell: ({ getValue, row }) => (
          <StatusCell
            currentStatus={getValue<ApplicationState>()}
            applicationTableItem={row.original}
          />
        ),
        filterFn: "defaultFilterFn",
        meta: {
          className: styles["column-nowrap"],
          filterOptions: getCurrentStatusFilterOptions(
            t,
            APPLICATION_STATE_GROUP.ACTIVE_GROUP,
            role
          ),
        },
      },
    ],
    [t, locale, isMobile, role, urlLink, transactionTypeFilterOptions]
  );

  const columns = useColumnsByRole(baseColumns);

  if (isError) {
    toast(
      <Typography size={0}>
        {t("activeApplications.notFound", { ns: "error" })}
      </Typography>,
      {
        type: "error",
        toastId: "active-applications-not-found",
      }
    );
  }

  return (
    <Table<ApplicationTableItem>
      className={styles["dashboard-table"]}
      data={results}
      columns={columns}
      loading={isPending}
      sortingOptions={{
        sorting: sortingParams,
        onSortingChange,
      }}
      columnFiltersOptions={{
        columnFilters: columnFiltersParams,
        onColumnFiltersChange,
      }}
      paginationOptions={{
        pageCount,
        pagination: paginationParams,
        onPaginationChange,
      }}
    >
      <Table.Header
        enableStickyHeader
        enableTableReset={!isExternalBroker as false}
        resetButtonLabel={t("table.header.resetButtonLabel", { ns: "common" })}
        selectAllFilterOptionLabel={t("table.header.selectAll", {
          ns: "common",
        })}
      />
      <Table.Body noResultsFoundLabel={""} />
      {!results.length && isFetched && (
        <tr className={styles.row}>
          <td className={styles.cell} colSpan={1000}>
            <EmptyState />
          </td>
        </tr>
      )}
      <Table.Footer
        className={clsx({
          [styles["dashboard-table__footer-mobile"]]: isExternalBrokerMobile,
        })}
        firstButtonLabel={t("table.footer.firstButtonLabel", { ns: "common" })}
        lastButtonLabel={t("table.footer.lastButtonLabel", { ns: "common" })}
        pageLabel={t("table.footer.pageLabel", { ns: "common" })}
        perPageLabel={t("table.footer.perPageLabel", { ns: "common" })}
        isPageLabelVisible={!isExternalBrokerMobile}
        isPerPageLabelVisible={!isExternalBrokerMobile}
        showFirstButton={!isExternalBrokerMobile}
        showLastButton={!isExternalBrokerMobile}
        siblingCount={isExternalBrokerMobile ? 0 : 1}
      />
    </Table>
  );
};
