import { useRef } from "react";

import { useGate } from "@nestoca/gate-react";
import {
  Box,
  Flex,
  Tooltip,
  Typography,
  useBreakpointValue,
} from "@nestoca/ui";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { ApplicationTableCoApplicantsByRole } from "@components/dashboard-table/types";
import { CoBorrowersByRole } from "@components/mortgages-table/types";

import styles from "./table-tooltip.module.scss";

type ApplicantToolTipProps = {
  id: number;
  applicantName: string;
  coApplicantsByRole: ApplicationTableCoApplicantsByRole | CoBorrowersByRole;
};

export const ApplicantToolTip = ({
  id,
  applicantName,
  coApplicantsByRole,
}: ApplicantToolTipProps) => {
  const { t } = useTranslation("common");
  const gate = useGate();

  const isExternalBroker = gate.getRole() === "externalbroker";
  const isMobile = useBreakpointValue({ default: true, md: false });

  const tooltipRef = useRef<HTMLElement | null>(null);

  const { nonGuarantorCoApplicants, guarantor } = coApplicantsByRole;

  const hasCoApplicants = !!(nonGuarantorCoApplicants.length > 0 || guarantor);

  return (
    <Box
      className={clsx(styles["table-tooltip"], styles["long-value"], {
        [styles["table-tooltip--mobile"]]: isExternalBroker && isMobile,
      })}
      data-tooltip-id={`${id}-tooltip`}
      data-tooltip-offset={16}
    >
      <Typography size={0} weight={hasCoApplicants ? 7 : undefined}>
        {applicantName}
      </Typography>
      {hasCoApplicants && (
        <Tooltip
          id={`${id}-tooltip`}
          className={styles["table-tooltip__tooltip"]}
          direction="top"
          float
          noArrow
          delayShow={500}
        >
          <Flex ref={tooltipRef} direction="column" gap={3}>
            {!!nonGuarantorCoApplicants.length && (
              <Flex direction="column" gap={1}>
                {nonGuarantorCoApplicants.map(
                  ({ firstName, lastName }, index) => (
                    <Typography key={index} size={0} weight={7}>
                      {firstName} {lastName}
                      {index !== nonGuarantorCoApplicants.length - 1 && ","}
                    </Typography>
                  )
                )}
                <Typography size="00">{t("coApplicant")}</Typography>
              </Flex>
            )}
            {guarantor && (
              <Flex direction="column" gap={1}>
                <Typography size={0} weight={7}>
                  {guarantor.firstName} {guarantor.lastName}
                </Typography>
                <Typography size="00">{t("guarantor")}</Typography>
              </Flex>
            )}
          </Flex>
        </Tooltip>
      )}
    </Box>
  );
};
